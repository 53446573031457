<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-upload</v-icon>{{ $t("global.import") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-alert v-if="!!error" color="error" text icon="mdi-close">
          {{ error }}
        </v-alert>
        <v-alert color="blue-grey" text icon="mdi-information-outline">
          {{ $t("configurations.import_zones_information") }}
        </v-alert>
        <v-alert v-if="replace" color="orange" text icon="mdi-alert-outline">
          <span v-html="$t('configurations.import_zones_warning')" />
        </v-alert>
        <v-form ref="form" class="mt-10">
          <v-file-input
            v-model="file"
            show-size
            accept=".geojson"
            outlined
            prepend-icon
            prepend-inner-icon="mdi-paperclip"
            :error-messages="fileUploadErrorMessage"
            :rules="fileRules"
            persistent-hint
            label="Ficheiro GeoJSON"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-model="replace"
          :label="$t('global.replace_data')"
        ></v-checkbox>
        <v-spacer />
        <v-btn
          depressed
          color="primary"
          text
          @click="importFile"
          :disabled="loading"
        >
          {{ $t("global.import") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open"],
  data() {
    return {
      error: null,
      file: null,
      fileUploadErrorMessage: "",
      fileRules: [(v) => !!v || this.$t("global.file_rule")],
      loading: false,
      replace: false,
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.replace = false;
        this.file = null;
      }
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    importFile() {
      this.error = null;
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("zones/IMPORT_ZONES", {
            file: this.file,
            replace: this.replace,
          })
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch((e) => {
            this.loading = false;
            this.error = this.$t("configurations.import_file_geojson_error", {
              server_error_code: e.response.status,
              server_error_message: JSON.stringify(e.response.data),
            });
          });
      }
    },
  },
};
</script>
