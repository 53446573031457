<template>
  <div class="map-container">
    <div ref="map" class="map" />
  </div>
</template>

<script>
import configs from "@/helpers/configs";
import * as maptalks from "maptalks";
const ZONES_LAYERNAME = "zones";
export default {
  props: ["selected"],
  data() {
    return {
      map: null,
      loading: false,
      zonesHighlightSymbol: {
        lineWidth: 2,
        polygonOpacity: 0.8,
      },
      zonesSymbol: {
        lineColor: "#34495e",
        lineWidth: 1,
        polygonFill: "rgb(231,209, 92)",
        polygonOpacity: 0.6,
        textFaceName: "Nunito",
        textName: "{code} - {name}", //value from name in geometry's properties
        textWeight: "bold", //'bold', 'bold'
        textStyle: "normal", //'italic', 'oblique'
        textSize: {
          stops: [
            [15, 0],
            [16, 12],
          ],
        },
        textOpacity: 1,
        textLineSpacing: 0,
        textDx: 0,
        textDy: 25,
        textHorizontalAlignment: "middle", //left | middle | right | auto
        textVerticalAlignment: "middle", // top | middle | bottom | auto
        textAlign: "center", //left | right | center | auto,
        textFill: "#34495e",
      },
    };
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
    zones() {
      return this.$store.state.zones.zonesList;
    },

    selectedZone: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
  },
  watch: {
    zones() {
      this.processZones();
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    this.map.off("click", this.handleMapClick);
  },
  methods: {
    initMap() {
      let center;
      let bearing;
      let zoom;
      try {
        center = this.locode.coordinates;
        bearing = this.locode.bearing;
        zoom = this.locode.zoom;
      } catch (e) {
        console.error(e);
        center = [-8.702487, 41.183199];
        bearing = -38;
        zoom = 15;
      }
      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        seamlessZoom: true,
        maxPitch: 0,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        seamlessZoom: true,
        attribution: false,
        layerSwitcherControl: {
          position: "top-left",
          // title of base layers
          baseTitle: "Base Layers",
          // title of layers
          overlayTitle: "Layers",
          // layers you don't want to manage with layer switcher
          excludeLayers: [],
        },

        baseLayer: new maptalks.GroupTileLayer("Base TileLayer", [
          new maptalks.TileLayer("baselayer", {
            visible: false,
            urlTemplate:
              "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
            subdomains: ["a", "b", "c", "d"],
            attribution:
              '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
          }),
          new maptalks.TileLayer("baselayer_nv", {
            visible: true,
            urlTemplate:
              "https://basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO",
          }),
          new maptalks.TileLayer("baselayer_nv_dark", {
            visible: false,
            urlTemplate:
              "https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png",
            subdomains: ["a", "b", "c", "d"],
            attribution: "OSM CARTO DARK",
          }),
        ]),
        layers: [new maptalks.VectorLayer(ZONES_LAYERNAME, [])],
      });

      this.map.on("click", this.handleMapClick);
    },

    loadZones() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("zones/GET_ZONES")
          .then(() => {
            this.loading = false;
            resolve();
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },

    processZones() {
      if (this.map) {
        let layer = this.map.getLayer(ZONES_LAYERNAME);
        layer.clear();
        if (this.zones) {
          this.zones.forEach((zone) => {
            let geometry = maptalks.GeoJSON.toGeometry(zone.geojson);
            geometry.setSymbol(this.zonesSymbol);
            geometry.setProperties({
              id: zone.id,
              code: zone.code,
              name: zone.name,
            });
            geometry.addTo(layer);
            geometry.on("click", this.handleZoneClicked);
          });
        }
      }
    },

    deSelectZones() {
      if (this.map) {
        this.map
          .getLayer(ZONES_LAYERNAME)
          .forEach((l) => l.setSymbol(this.zonesSymbol));
      }
      this.selectedZone = [];
    },

    handleZoneClicked(e) {
      if (e && e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      this.deSelectZones();
      if (e.target) {
        let found = this.zones.find((z) => {
          return z.id == e.target.properties.id;
        });
        if (found) {
          this.selectedZone = [found];
        }
        e.target.updateSymbol(this.zonesHighlightSymbol);
      }
    },
    handleMapClick() {
      this.deSelectZones();
    },
  },
};
</script>

<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 150px);
  overflow: hidden;
  position: relative;
}
</style>
<style scoped src="@/../node_modules/maptalks/dist/maptalks.css"></style>
