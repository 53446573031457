<template>
  <v-dialog v-model="isOpen" persistent scrollable width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="lime"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon
            >{{ $t("configurations.edit_zone") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="50"
            maxlength="255"
            v-if="!!itemToEdit"
            v-model="itemToEdit.code"
            required
            :label="$t('global.code')"
            :rules="codeRules"
            outlined
          />
          <v-text-field
            counter="100"
            maxlength="255"
            v-if="!!itemToEdit"
            v-model="itemToEdit.name"
            required
            :label="$t('global.name')"
            :rules="nameRules"
            outlined
          />
          <v-select
            :items="items"
            v-model="typeOfland"
            :label="$t('global.local')"
            :rules="landRules"
            :menu-props="{ offsetY: true }"
            outlined
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save" :disabled="loading">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      codeRules: [(v) => !!v || this.$t("global.code_rules")],
      nameRules: [(v) => !!v || this.$t("global.name_rules")],
      landRules: [(v) => !!v || this.$t("global.local_rules")],
      items: [this.$t("configurations.land"), this.$t("configurations.water")],
      typeOfland: null,
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$store.dispatch("zones/GET_ZONES");
        this.typeOfland = this.itemToEdit.land
          ? this.$t("configurations.land")
          : this.$t("configurations.water");
      }
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit() {
      return this.item[0] || null;
    },
    isUnavailable: {
      get() {
        return this.itemToEdit && this.itemToEdit.unavailable;
      },
      set(val) {
        if (this.itemToEdit) {
          if (val) {
            this.itemToEdit.unavailable = true;
          } else {
            this.itemToEdit.unavailable = false;
          }
        }
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.itemToEdit.land = this.typeOfland === "Cais" ? true : false;
        this.$store
          .dispatch("zones/UPDATE_ZONE", this.itemToEdit)
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
