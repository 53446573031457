<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.zones") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.zones_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div v-if="hasUserPermissionToViewOrManage('ZONES')" class="ml-auto">
        <div v-if="$vuetify.breakpoint.lgAndDown">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="black--text"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                depressed
              >
                <v-icon color="white"> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="hasUserPermissionToViewEditOrManage('ZONES')"
                @click="openImportDialogAction"
              >
                <v-list-item-avatar class="mr-2">
                  <v-icon left> mdi-upload</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.import") }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="openExportDialogAction">
                <v-list-item-avatar class="mr-2">
                  <v-icon left>mdi-download</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.export") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn
            v-if="hasUserPermissionToViewEditOrManage('ZONES')"
            color="primary"
            @click="openImportDialogAction"
            depressed
          >
            <v-icon left>mdi-upload</v-icon>
            {{ $t("global.import") }}
          </v-btn>

          <v-btn
            class="ml-2"
            color="primary"
            @click="openExportDialogAction"
            depressed
          >
            <v-icon left>mdi-download</v-icon>
            {{ $t("global.export") }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-row class="fill-height">
      <v-col cols="6" sm="6" md="6" lg="7">
        <Map :selected.sync="selectedZone" />
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="5">
        <v-card tile elevation="0" outlined>
          <v-card-text class="ma-0 pa-0">
            <List
              :selected.sync="selectedZone"
              :key="componentKey"
              :search.sync="search"
              :page.sync="page"
              @remove="removeZone"
              @edit="editZone"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ImportZonesDialog :open.sync="openImportZonesDialog" />
    <EditZonesDialog :open.sync="openEditZoneDialog" :item="selectedZone" />
    <DeleteZonesDialog :open.sync="openDeleteZoneDialog" :item="selectedZone" />
    <ExportZonesDialog :open.sync="openExportZonesDialog" />
  </div>
</template>

<script>
import Map from "./Zones/Map";
import List from "./Zones/List";
import ImportZonesDialog from "./Zones/ImportZonesDialog";
import EditZonesDialog from "./Zones/EditZonesDialog";
import DeleteZonesDialog from "./Zones/DeleteZonesDialog";
import ExportZonesDialog from "./Zones/ExportZonesDialog";

export default {
  components: {
    Map,
    List,
    ImportZonesDialog,
    EditZonesDialog,
    DeleteZonesDialog,
    ExportZonesDialog,
  },
  data() {
    return {
      componentKey: 0,
      tab: null,
      search: "",
      page: 1,
      selectedZone: [],
      openEditZoneDialog: false,
      openDeleteZoneDialog: false,
      openImportZonesDialog: false,
      openExportZonesDialog: false,
    };
  },
  watch: {
    selectedZone() {
      if (!this.openEditZoneDialog && !this.openDeleteZoneDialog) {
        if (this.selectedZone && this.selectedZone[0]) {
          this.search = this.selectedZone[0].name;
          this.page = 1;
        } else {
          this.search = "";
        }
      }
    },
  },
  methods: {
    openImportDialogAction() {
      this.openImportZonesDialog = true;
    },
    openExportDialogAction() {
      this.openExportZonesDialog = true;
    },
    removeZone(item) {
      this.openDeleteZoneDialog = true;
      this.selectedZone = [item];
    },
    editZone(item) {
      this.openEditZoneDialog = true;
      this.selectedZone = [item];
    },
  },
};
</script>
