<template>
  <div class="ma-5">
    <v-data-table
      v-model="selectedZone"
      :loading="loading"
      :search="searchTerm"
      :page.sync="pageIndex"
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      item-key="id"
      fixed-header
      sort-by="name"
      must-sort
      :hide-default-footer="true"
      height="calc(100vh - 280px)"
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchTerm"
          single-line
          :label="$t('global.search')"
          prepend-inner-icon="mdi-magnify"
          outlined
          clearable
        />
      </template>
      <template v-slot:item.land="{ item }">
        <v-chip
          small
          outlined
          :color="item.land ? 'red darken-2' : 'primary darken-2'"
          >{{ displayLand(item.land) }}</v-chip
        >
      </template>

      <template v-slot:item.code="{ item }">
        <b class="font-weight-black">{{ item.code }}</b>
      </template>

      <template
        v-if="hasUserPermissionToViewEditOrManage('ZONES')"
        v-slot:item.actions="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="hasUserPermissionToManage('ZONES')"
              @click="removeZone(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToViewEditOrManage('ZONES')"
              @click="editZone(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: ["selected", "search", "page"],
  data() {
    return {
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("global.code"),
          value: "code",
        },
        {
          text: this.$t("global.name"),
          value: "name",
        },
        {
          text: this.$t("global.local"),
          value: "land",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.zones.zonesList;
    },
    searchTerm: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit("update:search", val);
      },
    },

    pageIndex: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    selectedZone: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
    loading() {
      return this.$store.state.zones.loading;
    },
  },
  mounted() {
    this.loadZones();
  },
  methods: {
    loadZones() {
      this.$store.dispatch("zones/GET_ZONES");
    },
    removeZone(item) {
      this.$emit("remove", item);
    },
    editZone(item) {
      this.$emit("edit", item);
    },
    displayLand(land) {
      return land
        ? this.$t("configurations.land")
        : this.$t("configurations.water");
    },
  },
};
</script>
